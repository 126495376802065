@import "/normalize";
@media screen and (max-width: 780px) {
  @import "node_modules/@glidejs/glide/src/assets/sass/glide.core";
}

/* -------------
VARIABLE
------------*/

$roza: #ff6680;
$rozasvetla: #ffe3e9;
$rozatemna: #ff99af;
$hoveroza: #ff1d4d;

@font-face {
  font-family: Sofia Pro;
  src: url("font/SofiaProBlack.otf") format("opentype");
  font-weight: 900;
}
@font-face {
  font-family: Sofia Pro;
  src: url("font/SofiaProRegular.otf") format("opentype");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: Sofia Pro;
  src: url("font/SofiaProMedium.otf") format("opentype");
  font-style: normal;
  font-weight: 500;
}

/* 	 ==========================================================================
                    
            MIXIN

	 ========================================================================== */
@mixin rob() {
  width: 100%;
  height: 100%;
  background: url("img/rob-zg.svg") left top no-repeat;
  background-size: 100% 20px;
}
@mixin rob-50() {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  border-left: 2px solid black;
  border-right: 2px solid black;
}
@mixin grid() {
  width: 100% !important;
  display: grid;
  grid-template-columns: 1fr;
  @media screen and (min-width: 780px) {
    grid-template-columns: 50% 50%;
  }
}
@mixin border {
  border-width: 1px 2px 1px 2px;
  border-color: #000;
  border-style: solid;
}
@mixin padd {
  padding: 30px 30px;
  @media screen and (min-width: 780px) {
    padding: 40px 40px;
  }
}
.wraper {
  max-width: 1240px;
  margin: auto;
}
.grid {
  @include grid();
}
.glide__slide {
  display: flex;
  flex-flow: column;
  height: 100%;

  .ilustracija-leva,
  .ilustraciija-desna {
    flex: 0 1 auto;
  }

  .tekst-levo,
  .tekst-desno {
    height: 100%;
  }
}
body {
  cursor: url("img/cn.png"), auto;
  .tekst-levo,
  .tekst-desno {
    @media screen and (min-width: 780px) {
      transform: rotate(180deg);
    }
    &:hover {
      cursor: url("img/ch.png"), auto;
    }
  }
  a {
    cursor: url("img/cn.png"), auto;
  }
}
.subheader {
  border-bottom: 1px solid black;
}
/* 	 ==========================================================================
                    
            STILI

	 ========================================================================== */
h1 {
  font-family: "Sofia Pro", sans-serif;
  font-style: normal;
  font-weight: 900;

  font-size: 80px;
  line-height: 87.7%;
  @media screen and (min-width: 780px) {
    font-size: 100px;
    line-height: 87.7%;
  }
}
h2 {
  font-family: "Sofia Pro", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 31.2863px;
  line-height: 31px;
  margin-top: 10px;
  text-align: left;
  @media screen and (min-width: 780px) {
    margin-top: 0px;
    font-size: 60px;
    line-height: 60px;
    text-align: center;
  }
  letter-spacing: -0.01em;
}
h3 {
  font-family: "Sofia Pro", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 34px;
  line-height: 34px;
}
h4 {
  font-family: "Sofia Pro", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  @media screen and (min-width: 780px) {
    font-size: 26px;
    line-height: 30px;
  }
  text-align: left;

  padding: 20px;
  a {
    font-family: "Sofia Pro", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    @media screen and (min-width: 780px) {
      font-size: 26px;
      line-height: 30px;
    }
    padding: 0px;
    text-decoration: none;
    color: black;
  }
}
h6 {
  font-family: "Sofia Pro", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  @media screen and (min-width: 780px) {
    font-size: 26px;
    line-height: 30px;
  }
}
p {
  font-family: "Sofia Pro", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  @media screen and (min-width: 780px) {
    font-size: 24px;
    line-height: 115.5%;
  }
  @include padd();
}
img {
  font-size: 0;
}
a {
  font-family: "Sofia Pro", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 23px;
  line-height: 23px;
  @media screen and (min-width: 780px) {
    font-size: 36px;
    line-height: 36px;
  }
  text-align: center;
  display: inline-block;

  @include padd();
}
.head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @include padd();
  border-width: 2px 2px 1px 2px;
  border-color: #000;
  border-style: solid;
  .logo {
    height: 110px !important;
    width: auto;
    img {
      height: 110px !important;
      width: auto;
    }
  }
}
header {
  background-color: $roza;
  min-height: 400px;
  display: flex;
  flex-direction: row-reverse;
  h3 {
    max-width: 285px;
    padding-top: 13px;
  }
}
.subheader {
  background-color: $roza;

  .wraper {
    max-width: 1240px;
    height: 40px;
    margin: auto;
    border-width: 1px 2px 0px 2px;
    border-color: #000;
    border-style: solid;
    idth: 100% !important;
    display: grid;
    grid-template-columns: 50% 50%;
    @media screen and (min-width: 500px) {
      height: 95px;
    }
    @media screen and (min-width: 780px) {
      height: 145px;
    }
  }
  .left {
    background-color: black;
    clip-path: polygon(
      100% calc(100% - 1px),
      0% calc(0% - 1px),
      0% calc(0% + 1px),
      100% calc(100% + 1px)
    );
  }
  .right {
    background-color: black;
    clip-path: polygon(
      0 calc(100% - 1px),
      100% calc(0% - 1px),
      100% calc(0% + 1px),
      0 calc(100% + 1px)
    );
  }
}
.naslovnica {
  @include grid();
  border-width: 1px 2px 1px 2px;
  border-color: #000;
  border-style: solid;
  &-text {
    padding: 30px;
    @media screen and (min-width: 780px) {
      padding: 40px;
    }
    border-width: 0px 0px 0px 0px;
    border-color: #000;
    border-style: solid;
    @media screen and (min-width: 780px) {
      border-width: 0px 2px 0px 0px;
    }
  }
  h1 {
    padding-top: 20px;
    @media screen and (min-width: 780px) {
      padding-top: 70px;
    }
    padding-bottom: 20px;
  }
  img {
    width: 100%;
    height: auto;
  }
}
main {
  overflow: hidden;
}

.naslovnica-logo {
  display: flex;
  align-items: flex-end;
  display: flex;
}
.logo {
  a {
    padding: 0px !important;
  }
}
.naslov {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 20px;
  @media screen and (min-width: 780px) {
    padding: 20px 30px;
    padding: 70px 35px 60px 35px;
  }
  border-width: 0px 2px 1px 2px;
  border-color: #000;
  border-style: solid;
}

.rozasvetla {
  background: $rozasvetla;
  .notranja-animacija {
    background: $rozasvetla;
  }
  border-bottom: 1px solid black;
  border-top: 1px solid black;
}
.rozatemna {
  background: $rozatemna;
  .notranja-animacija {
    background: $rozatemna;
  }
  border-bottom: 1px solid black;
  border-top: 1px solid black;
}
.notranja-animacija {
  p {
    @media screen and (max-width: 780px) {
      padding-bottom: 45px;
    }
  }
}
.ilustracija {
  &-leva {
    font-size: 0px;
    &-slika {
      font-size: 0px;
    }
  }
  &-desna {
    font-size: 0px;
    &-slika {
      font-size: 0px;
    }
  }
}
.tekst {
  @include grid();
}
.razlaga,
.credits {
  background: $roza;
  @include grid();
  padding: 10px;
  @media screen and (min-width: 780px) {
    padding: 20px;
  }
  h4,
  a {
    text-align: left !important;
  }
}

.povezava {
  max-width: 1240px;
  margin: auto;
  position: relative;
  text-align: center;
  padding: 15px;
  @media screen and (min-width: 780px) {
    padding: 25px;
  }
  transition: all 0.2s;
  border-width: 1px 2px 0px 2px;
  border-color: #000;
  border-style: solid;
  &:hover {
    background-color: $hoveroza;
    transition: all 0.2s;
  }
  .rob {
    padding: 15px;
    @media screen and (min-width: 780px) {
      padding: 25px;
    }
    border: 2px solid #000;
  }
  &-b {
    a {
      padding: 5px 10px;
      position: relative;
      font-style: normal;
      font-weight: 900;
      text-align: center;
      text-decoration: none;
      color: #000000;
      img {
        transform: translateY(-5px);
      }
    }
  }
}

.notranja-animacija {
  //display: table;
  height: 100% !important;
  border-width: 1px 1px 1px 1px;
  border-color: #000;
  border-style: solid;
}

// novi grid

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: minmax(min-content, max-content);
  gap: 0px 0px;
  grid-template-areas:
    "ilustracija-leva ilustraciija-desna"
    "tekst-levo tekst-desno";
  border-width: 1px 1px 0px 1px;
  border-color: #000;
  border-style: solid;
}
.ilustracija-leva {
  grid-area: ilustracija-leva;
  border-width: 0px 1px 1px 1px;
  border-color: #000;
  border-style: solid;
}
.ilustraciija-desna {
  grid-area: ilustraciija-desna;
  border-width: 0px 1px 1px 1px;
  border-color: #000;
  border-style: solid;
}
.tekst-levo {
  grid-area: tekst-levo;
  z-index: 99 !important;
}
.tekst-desno {
  grid-area: tekst-desno;
  z-index: 99 !important;
}
.tekst-levo,
.tekst-desno {
  @media screen and (min-width: 780px) {
    transform: rotate(180deg);
  }
}
.text-wrap {
  height: 100%;
}
.prefooter {
  background-color: $roza;

  .wraper {
    border-width: 1px 2px 0px 2px;
    border-color: #000;
    border-style: solid;
  }
}
.credits {
  border-width: 2px 0px 0px 0px;
  border-color: #000;
  border-style: solid;
}
footer {
}
footer {
  background: $roza;

  .wraper {
    border-width: 2px 2px 2px 2px;
    border-color: #000;
    border-style: solid;
    padding: 20px 20px 15px 20px;
    @media screen and (min-width: 780px) {
      padding: 30px 30px 10px 30px;
    }
  }
  a {
    padding: 0px;
  }
  background-color: $roza;
  .wraper {
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    @media screen and (max-width: 499px) {
      .logo {
        img {
          height: 40px;
        }
      }
    }
    @media screen and (min-width: 374px) {
      flex-direction: row;
      .logo-prvi {
        flex-basis: 100%;
      }
      .logo-drugi,
      .logo-tretji {
        flex-shrink: 1;
      }
      .logo-tretji {
        padding: 1px 0 0 7%;
      }
    }
    @media screen and (min-width: 500px) and (max-width: 779px) {
      .logo {
        img {
          height: 60px;
        }
      }
      flex-direction: row;
      .logo-prvi {
        flex-basis: 100%;
      }
      .logo-drugi,
      .logo-tretji {
        flex-shrink: 1;
      }
      .logo-tretji {
        padding: 0px 0 0 0;
      }
      .logo-drugi {
        padding: 0px 5% 0 0;
      }
    }
    @media screen and (min-width: 780px) {
      flex-direction: row;
      flex-wrap: nowrap;
      img {
        width: 100%;
        max-height: 100%;
      }
      .logo-prvi {
        flex-basis: auto;
        padding: 0 80px 0 0;
      }
      .logo-tretji {
        padding: 0px 0 0 80px;
      }
    }
  }
  //    display: flex;
  //    flex-wrap: wrap;
  //    justify-content: flex-start;
  //    grid-template-columns: 1fr;
  //    gap: 30px;
  //    img {
  //      max-height: 80px;
  //    }
  //
  //    @media screen and (min-width: 780px) {
  //      flex-wrap: nowrap;
  //      justify-content: space-between;
  //      a {
  //        &:first-of-type {
  //          padding: 0 80px 0 0;
  //        }
  //        &:last-of-type {
  //          padding: 5px 0 0 80px;
  //        }
  //      }
  //      img {
  //        max-width: 100%;
  //      }
  //    }
}

/* pike za slider */
/* pike za slider */
@media screen and (max-width: 780px) {
  .notranja-animacija {
    position: relative;
  }
  .glide__slide--active {
    .tekst-levo {
      .text-wrap p {
        &:before {
          position: absolute;
          bottom: 17px;
          left: calc(50% - 10px);
          content: "\A";
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background: #000;
          display: inline-block;
        }
        &:after {
          content: "\A";
          position: absolute;
          bottom: 20px;
          left: calc(50% + 10px);
          width: 7px;
          height: 7px;
          border-radius: 50%;
          background: #000;
          display: inline-block;
        }
      }
    }
    .tekst-desno {
      .text-wrap p {
        &:after {
          position: absolute;
          bottom: 17px;
          left: calc(50% + 8px);
          content: "\A";
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background: #000;
          display: inline-block;
        }
        &:before {
          content: "\A";
          position: absolute;
          bottom: 20px;
          left: calc(50% - 7px);
          width: 7px;
          height: 7px;
          border-radius: 50%;
          background: #000;
          display: inline-block;
        }
      }
    }
  }
}
